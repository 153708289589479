import React, { useEffect } from 'react'

const CancerSurvivorship = () => {
  useEffect(() => {
    window.location.href =
      'https://www.goodpath.com/conditions/cancer-survivorship'
  }, [])

  return null
}

export default CancerSurvivorship
